import React from 'react'
import { LiaUserEditSolid } from "react-icons/lia";
import { FiLock } from "react-icons/fi";
import { GrTicket } from "react-icons/gr";
import { FaRegBell } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';

const menuItems = [
    
    {
        title: "Account",
        icon: <FiLock />,
        link: "/profile",
    },

    {
        title: "Profile Information",
        icon: <LiaUserEditSolid  />,
        link: "/profile/personal-info",
    },
    // {
    //     title: "My Tickets",
    //     icon: <GrTicket  />,
    //     link: "/profile/my_tickets",
    // },
    // {
    //     title: "Notification",
    //     icon: <FaRegBell />,
    //     link: "/profile/notifications",
    // },
]

function Sidebar() {

    const location = useLocation();
    const pathname = location.pathname;

  return (
    <div className=' sticky top-0 self-start flex flex-col'>
        {menuItems.map((item,i) => (
            <Link to={item.link} className={` border border-neutral-300 pl-6 pr-6 lg:pr-40 text-sm ${pathname === item.link ? "text-white bg-gradient-to-tr from-blue-400 to-green-400" : "text-heading bg-white hover:bg-neutral-200"}  py-4 flex items-center gap-3 font-semibold `}>
                {item.icon}
                {item.title}
            </Link>
        ))}
    </div>
  )
}

export default Sidebar;