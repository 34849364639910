import React, { useContext, useEffect, useState } from "react";
import { IoMdPin } from "react-icons/io";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import Datepicker from "react-tailwindcss-datepicker";
import { FiLock } from "react-icons/fi";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from "recharts";
import axios from "axios";
import { WalletInfoContext } from "../../hooks/useWalletInfo";
import AnalyticsLoader from "../../componets/loader/AnalyticsLoader";

const data = [
  {
    name: "Page A",
    uv: 500,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 2000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 1000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 3000,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 2000,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data02 = [
  { name: "Group A", value: 100 },
  { name: "Group B", value: 20 },
];

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
function Analytics() {
  const { walletInfo } = useContext(WalletInfoContext);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [depositData, setDepositData] = useState([]);
  const [crypto, setCryptoTransaction] = useState([]);
  const [propertyUpdateData, setPropertyUpdateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiDepositeUrl = `${baseUrl}/v0/user/getTransactionInfo`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiDepositeUrl, {
          headers: {
            accept: "*/*",
            token: token,
          },
        });

        setDepositData(response.data.responseData);
        setIsLoading(false);
      } catch (error) {
        console.error("There was an error making the request:", error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    const fetchCryptoTransaction = async () => {
      try {
        const response = await axios.get(`${baseUrl}/v0/crypto-transactions`, {
          headers: {
            token: token,
          },
        });

        setCryptoTransaction(response.data.responseData.tokenTransactions);
      } catch (error) {
        console.error("There was an error making the request:", error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchData();
    fetchCryptoTransaction();
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/v0/nft/fractions-list-by-user`,
          {
            headers: {
              accept: "*/*",
              token: token,
            },
          }
        );
        console.log("Response:", response.data);
        setPropertyUpdateData(response.data.responseData);
        console.log(propertyUpdateData);
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    };

    fetchProductData();
  }, []);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  useEffect(() => {
    const calculateInvestedAmount = () => {
      let total = 0;
      depositData.forEach((deposit) => {
        if (deposit.transactionStatus === "SUCCESS") {
          total += Number(deposit.price);
        }
      });
      setTotalInvestedAmount(total);
    };

    calculateInvestedAmount();
  }, [depositData]);

  return (
    <div className="w-full">
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <div class="loader"></div>
        </div>
      ) : (
        <div className="p-10 md:p-20 w-full flex flex-col gap-20">
          <div className=" flex flex-wrap md:scale-100 scale-95 justify-center w-full py-5 rounded-lg shadow-md bg-white">
            <div className=" flex flex-col gap-3 py-5 px-20 border-transparent xl:border-r xl:border-black">
              <h1 className=" text-xl font-semibold text-neutral-500">
                Total Portfolio Value
              </h1>
              <span className=" text-lg text-neutral-900 font-semibold">
                USD {walletInfo?.inramount}
              </span>
            </div>
            <div className=" flex flex-col gap-3 py-5 px-10  border-transparent xl:border-r xl:border-black">
              <h1 className=" text-xl font-semibold text-neutral-500">
                Total Rental to date
              </h1>
              <span className=" text-lg text-primary-1 font-semibold">
                USD 0.00
              </span>
            </div>
            <div className=" flex flex-col gap-3 py-5 px-10 border-transparent xl:border-r xl:border-black">
              <h1 className=" text-xl font-semibold text-neutral-500">
                Current Month Rental Received
              </h1>
              <span className=" text-lg text-primary-1 font-semibold">
                USD 0.00
              </span>
            </div>
            <div className=" flex flex-col py-5 px-10 gap-3">
              <h1 className=" text-xl font-semibold text-neutral-500">
                Active Investments
              </h1>
              <span className=" text-lg text-neutral-900 font-semibold">
                USD {totalInvestedAmount}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-8 w-full ">
            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <h1 className="text-lg text-neutral-800 font-semibold">
                Deposits
              </h1>
              <div className="relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left  text-gray-500">
                  <thead className="text-xs text-white uppercase  bg-primary-1">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Sr No.
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      <th scope="col" className="py-3 px-6">
                        HASH
                      </th>
                    </tr>
                  </thead>
                </table>
                <div
                  className="overflow-x-auto overflow-y-auto"
                  style={{ maxHeight: "210px" }}
                >
                  <table className="w-full text-sm text-left text-gray-500">
                    <tbody>
                      {Array.isArray(depositData) && depositData.length > 0 ? (
                        depositData.map((d, i) => (
                          <tr className="bg-white border-b" key={i}>
                            <td className="py-4 px-10">{i + 1}</td>
                            <td className="py-4 pl-5 pr-6">{d.price}</td>
                            <td className="py-4 px-6 text-primary-1">
                              {d.transactionStatus}
                            </td>
                            <td className="py-4 px-6 text-center">
                              <a
                                href={`https://amoy.polygonscan.com/tx/${d.transactionHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="/images/polygon.png"
                                  alt="Transaction Link"
                                  className="w-6 h-6" // You can adjust the width and height as needed
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-white border-b">
                          <td colSpan="3" className="py-4 px-6 text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <div className=" flex flex-wrap gap-3 justify-between">
                <h1 className=" text-lg text-neutral-800 font-semibold">
                  Portfolio Allocation{" "}
                </h1>
                <div className=" flex gap-8">
                  <button className=" text-neutral-900 outline py-1 px-3 rounded-lg hover:bg-black hover:text-white transition-all  text-base font-semibold">
                    Area
                  </button>
                  <button className=" text-white bg-neutral-900  py-1 px-2  rounded-lg  text-base font-semibold">
                    Category
                  </button>
                </div>
              </div>

              <div className=" flex flex-col-reverse gap-3 md:gap-0 md:flex-row w-full ">
                <div className=" w-full md:w-2/3 ">
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={data02}
                        innerRadius={70}
                        outerRadius={100}
                        fill="#04A287"
                      />
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                <div className=" w-full md:w-1/3 flex h-fit border-2 p-3 items-center border-black rounded-lg gap-3">
                  <IoMdPin className=" text-2xl text-neutral-400" />
                  <div className=" w-full flex flex-col gap-3">
                    <h1 className=" text-sm text-neutral-400 font-semibold">
                      Empty
                    </h1>
                    <div className=" w-full bg-primary-1 h-2 rounded-full"></div>
                    <span className=" text-sm text-neutral-400 font-semibold">
                      100%
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <h1 className=" text-lg text-neutral-800 font-semibold">
                Property Updates
              </h1>
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg overflow-y-auto"  style={{ maxHeight: "400px" }}>
              
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-white  uppercase bg-primary-1">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Sr No.
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date of investment
                      </th>
                    </tr>
                  </thead>
                  <tbody className="overflow-y-scroll">
                    {propertyUpdateData.length > 0 ? (
                      propertyUpdateData.flatMap((fraction, i) =>
                        fraction.noOfPurchaseUser.map((user, j) => (
                          <tr key={user._id} className="bg-white border-b">
                            <td className="py-4 px-6">
                              {i + 1}.{j + 1}
                            </td>
                            <td className="py-4 px-6">{user.investPrice}</td>
                            <td className="py-4 px-6">
                              {formatDate(user.purchaseDate)}
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr className="bg-white border-b">
                        <td colSpan="3" className="py-4 px-6 text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <div className=" flex flex-wrap gap-3 justify-between items-center">
                <h1 className=" text-lg text-neutral-800  font-semibold">
                  Rental Received
                </h1>
                <Datepicker
                  inputClassName=" bg-white border-2 rounded-md w-56 p-2 font-semibold"
                  containerClassName=" relative mr-2"
                  toggleClassName="hidden"
                  primaryColor={"green"}
                  displayFormat={"DD/MM/YYYY"}
                  value={value}
                  onChange={handleValueChange}
                  showShortcuts={true}
                />
              </div>
              <div className=" w-full ">
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis />
                    <YAxis />
                    <Tooltip />
                    <Area
                      dataKey="uv"
                      stroke="#04A287"
                      fill="#04A287"
                      fillOpacity={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <h1 className="text-lg text-neutral-800 font-semibold">
                Transaction Details
              </h1>
              <div className="relative shadow-md sm:rounded-lg overflow-x-auto overflow-y-auto" style={{ maxHeight: "400px" }}>
                <table className="w-full text-sm text-left  text-gray-500">
                  <thead className="text-xs text-white uppercase bg-primary-1">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Sr No.
                      </th>
                      <th scope="col" className="py-3 px-6">
                        OG
                      </th>
                      <th scope="col" className="py-3 px-6">
                        USDT
                      </th>
                      <th scope="col" className="py-3 px-6">
                        STATUS
                      </th>
                      <th scope="col" className="py-3 px-6">
                        HASH
                      </th>
                    </tr>
                  </thead>
               
                
                 
                    <tbody className=" overflow-y-scroll">
                      {Array.isArray(crypto) && crypto.length > 0 ? (
                        crypto.map((d, i) => (
                          <tr className="bg-white border-b" key={i}>
                            <td className="py-4 px-8 text-center">{i + 1}</td>
                            <td className="py-4 pl-10 pr-6 text-center">
                              {d.ogAmount}
                            </td>
                            <td className="py-4 pl-10 pr-6 text-center">
                              {d.usdtAmount}
                            </td>
                            <td
                              className={`py-4 px-6 text-center ${
                                d.transactionStatus === "FAILED"
                                  ? "text-red-500"
                                  : "text-green-500"
                              }`}
                            >
                              {d.transactionStatus}
                            </td>
                            <td className="py-4 px-6 text-center">
                              <a
                                href={`https://amoy.polygonscan.com/tx/${d.transactionHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="/images/polygon.png"
                                  alt="Transaction Link"
                                  className="w-6 h-6" // You can adjust the width and height as needed
                                />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-white border-b">
                          <td colSpan="5" className="py-4 px-6 text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
         
                </table>
              </div>
            </div>
            <div className="lg:col-span-2 col-span-4 bg-white p-5 rounded-lg flex flex-col gap-4 shadow-md">
              <h1 className=" text-lg text-neutral-800 font-semibold">
                Rental Statement
              </h1>
              <p className=" text-sm text-neutral-500 font-semibold">
                No rental on selected date range
              </p>
              <h1 className=" text-sm text-center bg-neutral-200 py-2 px-2 text-neutral-500 font-semibold">
                0-0 of 0 Records
              </h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Analytics;
