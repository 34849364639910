const faqItems = [
      {
        "question": "Why should I use the Originals platform?",
        "answer": "Originals's platform offers a unique investment opportunity, providing access to tokenized real-world assets, diversification, and potential for higher returns, all while ensuring transparency, security, and regulatory compliance. With a user-friendly interface and lower barriers to entry, Originals makes it easy to invest in exclusive assets and manage your portfolio with confidence."
      },
      {
        "question": "Is my investment secure on our platform?",
        "answer": "Yes, my investment is secure on Originals's platform, which prioritizes security and integrity through robust blockchain technology, reputable custodians, and strict regulatory compliance. With multi-layered security measures, regular audits, and risk assessments, I can have confidence that my investments are protected and secure."
      },
      {
        "question": "How do I create an account?",
        "answer": "To create an account, click on the 'Sign Up' button on the top right corner of the website, and follow the registration process."
      },
      {
        "question": "What are the available assets on Originals?",
        "answer": "Originals currently supports OG, USDT, and RWA - SEC TOKEN."
      },
      {
        "question": "How do I acquire and use USDT, OG, and RWA SEC token?",
        "answer": "On signup, you will initially receive 50 USDT and 50 OG tokens. For further investments, convert USDT to OG tokens as needed on our platform. Receive RWA tokens in return for investing OG tokens in RWA-based real estate properties."
      },
      {
        "question": "What is the staking feature on our platform?",
        "answer": "The staking feature on our platform allows you to earn rewards by locking your OG tokens for a specified period. You can choose from different lock periods, ranging from 30 days to 365 days, and select between two staking options: Stake 2.0 or Stake 1.0. Each option offers varying reward rates and benefits based on the duration of your lock period. Staking helps you earn additional tokens as rewards for committing your funds for the chosen time frame."
      },
      {
        "question": "How many staking cycles does our platform offer?",
        "answer": "Our platform offers five staking cycles in both Stake 2.0 and Stake 1.0, with lock periods of 30 days, 60 days, 90 days, 180 days, and 365 days."
      },
      {
        "question": "What are the differences between Stake 2.0 and 1.0?",
        "answer": "The differences between Stake 2.0 and Stake 1.0 are: Reward Rates: Stake 2.0 generally offers higher reward rates compared to Stake 1.0 for the same lock periods. Stake 2.0 Rewards: 30 Days: 2%, 60 Days: 5%, 90 Days: 12%, 180 Days: 18%, 365 Days: 24% Stake 1.0 Rewards: 30 Days: 1%, 60 Days: 3%, 90 Days: 10%, 180 Days: 15%, 365 Days: 22% Reward Structure: The reward percentages vary between the two options, with Stake 2.0 providing higher returns for each lock period compared to Stake 1.0. Both sections offer the same lock periods but with different reward rates. Choose the one that aligns best with your staking goals."
      },
      {
        "question": "How do I participate in staking on our platform?",
        "answer": "If you have OG tokens, you can go to the 'Stake and Farm' page. There, you have two options: Stake 2.0 and Stake 1.0. Both sections offer 5 lock periods ranging from 30 days to 365 days, each with different types of rewards. If you don't have OG tokens but have USDT, you can go to the 'Buy and Sell' page to convert your USDT to OG tokens. Once converted, you can participate in the desired lock period."
      },
      {
        "question": "What rewards can I expect from staking?",
        "answer": "The rewards you can expect from staking depend on the lock period you choose and whether you stake in the Stake 2.0 or Stake 1.0 section. The longer you lock your OG tokens, the higher the rewards you can earn."
      },
      {
        "question": "How are staking rewards calculated?",
        "answer": "Staking rewards are calculated based on the amount of OG tokens you stake, the lock period you choose, and the associated reward rate. For example, if you stake 100 OG tokens in Stake 2.0 for 30 days at a 2% reward rate, you'll earn 2 OG as a reward. The longer the lock period, the higher the reward rate."
      },
      {
        "question": "What is the minimum amount required to stake in each cycle?",
        "answer": "You can start staking with a minimum of 1 OG token in each cycle."
      },
      {
        "question": "Can I unstake my cryptocurrency?",
        "answer": "Yes, you can unstake your cryptocurrency once the lock periods are completed."
      },
      {
        "question": "Can I change my staking amount once I've started a cycle?",
        "answer": "Yes, while the lock period is ongoing, you can add to the staking amount, and the lock period will restart."
      },
      {
        "question": "Can I withdraw my tokens before the end of the staking lock period?",
        "answer": "Yes, we can perform an emergency unstake, but in this case, we won't receive any rewards."
      },
      {
        "question": "How long does it take to stake my cryptocurrency?",
        "answer": "The staking process is usually instantaneous, but it may take a few seconds to confirm the transaction on the blockchain."
      },
      {
        "question": "What happens if I lose my password?",
        "answer": "You can reset your password by clicking on a 'Forgot Password' link, which will guide you through a series of steps to verify your identity. This often involves receiving a verification code via email or SMS, or answering security questions. It's important to follow these steps carefully to regain access to your account."
      },
      {
        "question": "Is my cryptocurrency safe on Originals?",
        "answer": "Yes, RWAs are tokenized representations of real-world assets, such as commodities, bonds, and stocks, that are stored and traded on the Originals platform."
      },
      {
        "question": "What are Real-World Assets (RWAs) on Originals?",
        "answer": "RWAs are tokenized representations of real-world assets, such as commodities, bonds, and stocks, that are stored and traded on the Originals platform."
      },
      {
        "question": "What are the benefits of RWAs on Originals?",
        "answer": "RWAs on Originals offer a secure, transparent, and efficient way to invest in and trade real-world assets, with the added benefits of blockchain technology."
      },
      {
        "question": "What is RWA tokenization on Originals?",
        "answer": "RWA tokenization on Originals is the process of representing ownership of a real-world asset (RWA) as a digital token on a blockchain."
      },
      {
        "question": "What are the benefits of RWA tokenization on Originals?",
        "answer": "RWA tokenization on Originals offers a secure, transparent, and efficient way to represent ownership of real-world assets, enabling fractional ownership, increased liquidity, and reduced transaction costs."
      },
      {
        "question": "How does RWA tokenization work on Originals?",
        "answer": "RWA tokenization on Originals works by creating a digital token that represents ownership or a claim on a real-world asset, which is then configured, deployed on a blockchain, and distributed to asset owners or investors, allowing for increased liquidity, transparency, and efficiency."
      },
      {
        "question": "What support is available for users encountering issues on our platform?",
        "answer": "At Originals, we're committed to providing a seamless and enjoyable experience for our users. If you encounter any issues on the platform. We’ve got you covered with multiple support channels: such as FAQs, Email Support, Community Forum, Social Media."
      },
      {
        "question": "What are fractional NFTs on our platform?",
        "answer": "Fractional NFTs on our platform are a type of NFT that allows multiple owners to share ownership of a single digital asset. This is achieved by dividing the NFT into smaller, tradable units called fractions."
      }
]

export default faqItems;