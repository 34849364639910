import React, { useEffect, useState } from "react";
import { MdAddToPhotos, MdKeyboardArrowLeft } from "react-icons/md";
import prop1 from "../../assets/images/property/prop6.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosArrowRoundBack, IoIosCheckmarkCircle } from "react-icons/io";
import { RiFolderAddFill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import AddCollectionModal from "../builderdashboard/components/AddCollectionModal";
import axios from "axios";
import toast from "react-hot-toast";
import "./admin.css";


function VerifyNft() {
  const { collectionId } = useParams();
  const [data, setData] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const collectionsPerPage = 10;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchProject = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/v0/collection/nft-list?collectionId=${collectionId}`,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data.responseData[0].data);
      console.log(response.data.responseData[0].data);
    } catch (error) {
      console.log(error);
    }finally {
      setIsInitialLoading(false);
    }
  };
  useEffect(() => {
    fetchProject();
  }, []);

  const handleVerify = async (id) => {
    setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
    const token = localStorage.getItem("token");
    const data = {
      type: "nft",
      nftId: id,
      collectionId: collectionId,
      verifyStatus: true,
    };
    const response = await axios.post(
      `${baseUrl}/v0/collectionAndNft/verify`,
      data,
      {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);
    if (response.data.success) {
      toast.success("Verified Successfully!");
      fetchProject();
    }
    setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
  };

  const handleUnverify = async (id) => {
    setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
    const token = localStorage.getItem("token");
    const data = {
      type: "nft",
      nftId: id,
      collectionId: collectionId,
      verifyStatus: false,
    };
    const response = await axios.post(
      `${baseUrl}/v0/collectionAndNft/verify`,
      data,
      {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data.success) {
      toast.success("Unpublished Successfully!");
      fetchProject();
    }
    setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
  };

  const indexOfLastCollection = currentPage * collectionsPerPage;
  const indexOfFirstCollection = indexOfLastCollection - collectionsPerPage;
  const currentCollections = data.slice(indexOfFirstCollection, indexOfLastCollection);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col p-10 bg-white">
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div class="loader"></div>
        </div>
      ) : (
        <>
      <Link to={`/admindashboard`} className=" flex items-center gap-4 group text-neutral-800 hover:text-neutral-500">
          <MdKeyboardArrowLeft className=" font-semibold group-hover:translate-x-1 transition-all" />
          <h1 className=" font-semibold ">back to collections</h1>
      </Link>
      <h1 className="text-3xl text-neutral-800 font-semibold text-center">
        Admin Dashboard
      </h1>
      <div className="flex p-1 items-center bg-[#dadada] rounded-full self-end">
        <button className="px-6 py-2 text-white text-xs font-semibold bg-[#4e4d4d] rounded-full">
          Properties
        </button>
        <button className="px-6 py-2 text-[#4e4d4d] font-semibold text-xs rounded-full">
          Analytics
        </button>
      </div>

      <div className="flex flex-col h-screen overflow-y-auto border border-[#a7a7a7] mt-10 rounded-md">
        <div className="sticky top-0 left-0 bg-white flex justify-between p-5 border-b border-[#a7a7a7]">
          <h1 className="text-xl font-semibold text-neutral-800">
            {data.length} {data.length > 1 ? "Properties" : "Property"}
          </h1>
          <Link to={`/builderaddnft/${collectionId}`}>
            <button className="overflow-hidden px-6 py-2 bg-[#4e4d4d] text-white border-none rounded-full text-sm font-bold cursor-pointer relative z-10 group">
              Add Property
              <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
              <span className="absolute w-36 h-32 -top-8 -left-2 bg-primary-1-light rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
              <span className="absolute w-40 h-32 -top-8 -left-2 bg-primary-1 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
              <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2 left-16 z-10">
                <MdAddToPhotos className="text-xl" />
              </span>
            </button>
          </Link>
        </div>

        <div className="flex flex-col h-full w-full gap-5 px-8 py-5">
          {data.length === 0 ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <RiFolderAddFill className="text-8xl text-primary-1" />
              <p className="w-[500px] text-center text-wrap text-lg font-semibold text-neutral-600">
                No Properties here, click on Add Property button to create your
                first collection{" "}
              </p>
            </div>
          ) : (
            currentCollections.map((d, i) => (
              <div
                className="flex px-5 py-3 items-center justify-between border border-[#a7a7a7] rounded-md shadow-md"
                key={i}
              >
                <img
                  src={d?.coverImage}
                  alt="collection"
                  className="w-16 h-16 rounded-md"
                />
                <h1 className="w-40 text-lg text-neutral-700 font-bold text-wrap">
                  {d?.tokenName}
                </h1>

                <div className="flex flex-col gap-2 items-center">
                  <h1 className="text-sm text-neutral-500 font-semibold">
                    Price
                  </h1>
                  <h1 className="text-lg text-neutral-700 font-bold">
                    {d?.price} USD
                  </h1>
                </div>

                <div className="flex flex-col gap-2 items-center">
                  <h1 className="text-sm text-neutral-500 font-semibold">
                    Number of Fractions
                  </h1>
                  <h1 className="text-lg text-neutral-700 font-bold">
                    {d?.NumberOfFractions}
                  </h1>
                </div>

                <div className="flex flex-col gap-2 items-center">
                  <h1 className="text-sm text-neutral-500 font-semibold">
                    Property Type
                  </h1>
                  <h1 className="text-lg text-neutral-700 font-bold">
                    {d?.PropertyType}
                  </h1>
                </div>

                <div className="flex flex-col gap-2 items-center">
                  <h1 className="text-sm text-neutral-500 font-semibold">
                    Admin Verified
                  </h1>
                  <h1 className="text-2xl text-primary-1 font-bold">
                    {d?.isVerify ? (
                      <IoIosCheckmarkCircle />
                    ) : (
                      <button
                        onClick={() => handleVerify(d?._id)}
                        className="bg-[#04A287] text-white px-2 py-1 rounded-md text-sm"
                        disabled={loadingStates[d?._id]}
                      >
                        {loadingStates[d?._id] ? "Processing..." : "Verify"}
                      </button>
                    )}
                  </h1>
                </div>

                <div className="relative">
                  <button className="text-neutral-800 peer focus:text-gray-200 transition-all duration-200">
                    <BsThreeDotsVertical className="text-3xl" />
                  </button>
                  <div
                    className='w-48 absolute top-5 right-2 z-10 shadow-lg
                    after:content-[""] after:inline-block after:absolute after:top-0 after:bg-white
                    after:w-full after:h-full after:-z-20  after:rounded-lg
                    peer-focus:top-10 peer-focus:opacity-100 peer-focus:visible 
                    transition-all duration-300 invisible  opacity-0'
                  >
                    <ul className="flex flex-col">
                      <li
                        onClick={() => {
                          if (d?.isVerify) {
                            handleUnverify(d?._id);
                          } else {
                            toast.error("Cannot unpublish an unverified property!");
                          }
                        }}
                        className={`cursor-pointer px-3 py-3 font-semibold rounded-md text-neutral-800 text-sm ${d?.isVerify ? "hover:bg-primary-1 hover:text-white hover:opacity-90" : "cursor-not-allowed opacity-50"}`}
                      >
                        Unpublish Property
                      </li>
                      <Link
                        to={`/collection/${collectionId}/editnft/${d?._id}`}
                      >
                        <li className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm">
                          Edit Property Info
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

      </div>
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: Math.ceil(data.length / collectionsPerPage) }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1 border rounded-md mx-1 ${
                currentPage === index + 1 ? "bg-[#04A287] text-white" : "bg-white text-[#4e4d4d]"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
        </>
      )}
    </div>
  );
}

export default VerifyNft;
