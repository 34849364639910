import React, { useContext, useEffect, useRef, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";
import { TbPigMoney } from "react-icons/tb";
import { BsCreditCard2Front } from "react-icons/bs";
import "./css/Account.css";
import axios from "axios";
import profilePlaceholder from "../../assets/images/profile/pic2.jpg";
import { WalletInfoContext } from "../../hooks/useWalletInfo";

function Account() {
  const [imageData, setImageData] = useState({
    profileImage: "",
    walletAddress: "",
  });

  const [usdtBalance, setUsdtBalance] = useState("0");
  const [ogBalance, setOgBalance] = useState("0");
  const { walletInfo } = useContext(WalletInfoContext);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      axios
        .get(`${baseUrl}/v0/user/profile?email=${email}`)
        .then((response) => {
          const data = response.data.responseData;
          if (data) {
            setImageData({
              profileImage: data.profilePic || profilePlaceholder,
              walletAddress: data.walletAddress || "",
            });
          }
          setIsInitialLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/v0/user/usdt-balance`,
          new URLSearchParams({ address: imageData.walletAddress }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setUsdtBalance(
          parseFloat(response.data.responseData).toFixed(2) || "0"
        );
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    };

    const fetchOGBalance = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/v0/user/og-balance`,
          new URLSearchParams({ address: imageData.walletAddress }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setOgBalance(parseFloat(response.data.responseData).toFixed(2) || "0");
      } catch (error) {
        console.error("Error fetching OG balance:", error);
      }
    };

    if (imageData.walletAddress) {
      fetchUSDTBalance();
      fetchOGBalance();
    }
  }, [imageData.walletAddress]);

  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [depositData, setDepositData] = useState([]);

  const token = localStorage.getItem("token");
  const apiDepositeUrl = `${baseUrl}/v0/user/getTransactionInfo`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiDepositeUrl, {
          headers: {
            accept: "*/*",
            token: token,
          },
        });

        setDepositData(response.data.responseData);
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateInvestedAmount = () => {
      let total = 0;
      depositData.forEach((deposit) => {
        if (deposit.transactionStatus === "SUCCESS") {
          total += Number(deposit.price);
        }
      });
      setTotalInvestedAmount(total);
    };

    calculateInvestedAmount();
  }, [depositData]);

  const inputRef = useRef(null);

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand("copy");
    alert("Copied the text: " + inputRef.current.value);
  };

  const amountInfo = [
    {
      title: "Total Portfolio Value",
      val: walletInfo?.inramount,
      icon: <BsCreditCard2Front />,
    },
    {
      title: "Total Rental to date",
      val: "0.00",
      icon: <GrMoney />,
    },
    {
      title: "Current Month Rental Received",
      val: "0.00",
      icon: <TbMoneybag />,
    },
    {
      title: "Active Investments",
      val: totalInvestedAmount,
      icon: <TbPigMoney />,
    },
  ];

  return (
    <>
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen w-full">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col gap-8 p-4 sm:p-6 md:p-10 lg:p-16 acc-container min-h-screen overflow-auto">
            <div className="relative flex flex-col w-full p-4 sm:p-6 md:p-8 lg:p-10 bg-white shadow-lg bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 text-white rounded-lg overflow-hidden">
              {/* Testnet Banner */}
              <div className="absolute top-0 left-0 bg-blue-500 text-white px-3 py-1 rounded-br-lg text-xs sm:text-sm font-semibold z-10">
                Testnet
              </div>
              {/* Header */}
              <div className="flex w-full justify-end z-10">
                <h1 className="text-lg sm:text-xl md:text-2xl italic">
                  BlockMosaic
                </h1>
              </div>
              {/* Wallet Section */}
              <div className="flex flex-col md:flex-row mt-6 mb-8 w-full overflow-hidden">
                <div className="flex flex-col w-full md:w-2/3 gap-4 sm:gap-6">
                  <h1 className="text-xl sm:text-2xl font-semibold">
                    Wallet Address
                  </h1>
                  <div className="w-full flex items-center overflow-hidden">
                    <input
                      className="bg-transparent w-full sm:w-[300px] md:w-[400px] focus:outline-none"
                      type="text"
                      name="name"
                      ref={inputRef}
                      value={imageData.walletAddress}
                      readOnly
                    />
                    <MdContentCopy
                      onClick={handleCopy}
                      className="text-neutral-200 cursor-pointer hover:text-black text-lg sm:text-xl"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row w-full items-center sm:items-start overflow-hidden">
                    {/* USDT Value */}
                    <div className="flex flex-col gap-3 justify-center border-b sm:border-b-0 sm:border-r border-white py-3 sm:py-0 sm:pr-6 w-full sm:w-1/2 md:w-1/3">
                      <h1 className="text-base sm:text-lg md:text-xl font-semibold">
                        USDT Value
                      </h1>
                      <h1 className="text-base sm:text-lg md:text-xl font-semibold">
                        {usdtBalance}
                      </h1>
                    </div>
                    {/* OG Value */}
                    <div className="flex flex-col gap-3 justify-center py-3 sm:py-0 sm:pl-6">
                      <h1 className="text-base sm:text-lg md:text-xl font-semibold">
                        OG Value
                      </h1>
                      <h1 className="text-base sm:text-lg md:text-xl font-semibold">
                        {ogBalance}
                      </h1>
                    </div>
                    {/* QR Code */}
                    <div className="flex justify-center w-full sm:w-auto mt-4 sm:ml-6">
                      <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${imageData.walletAddress}`}
                        className="w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] mx-auto"
                        alt="QR Code"
                      />
                    </div>
                  </div>
                </div>
                {/* Profile Image */}
                <div className="w-full md:w-1/3 flex justify-center items-center mt-6 md:mt-0">
                  <img
                    src={imageData.profileImage}
                    alt="profile pic"
                    className="w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] md:w-[200px] md:h-[200px] rounded-full"
                  />
                </div>
              </div>
              {/* Amount Info Grid */}
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 overflow-hidden">
                {amountInfo.map((d, i) => (
                  <div
                    className="p-4 flex justify-between bg-primary-1 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-xl rounded-xl"
                    key={i}
                  >
                    <div className="flex flex-col">
                      <h1 className="text-sm sm:text-base md:text-lg capitalize text-white">
                        {d.title}
                      </h1>
                      <span className="text-sm sm:text-base md:text-lg font-semibold text-white">
                        USD {d.val}
                      </span>
                    </div>
                    <div className="text-white self-end text-lg sm:text-xl">
                      {d.icon}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Account;
