import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBanner from './components/TopBanner';
import Sidebar from './components/Sidebar';

const Layout = () => {
  return (
    <div className="w-full min-h-screen">
      <div className="w-full">
        <TopBanner />
      </div>
      <div className="flex flex-col lg:flex-row w-full items-center lg:items-start">
        <div className="w-full md:w-1/4 sm:w-1/3 lg:w-1/4 lg:flex-shrink-0">
          <Sidebar />
        </div>
        <div className="w-full lg:w-full p-4 lg:pr-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
