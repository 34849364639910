import React, { useEffect, useState } from 'react'

import { HiMiniGlobeAlt } from "react-icons/hi2";
import { FaTelegramPlane } from "react-icons/fa";
import { TiSocialTwitter } from "react-icons/ti";
import walletLogo from "../../assets/images/wallet.png"
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';
import { get } from '../../Service/Apiservice';

function ProjectDetail() {
    const {projectId} = useParams();
    const { getprojects } = config;
    const [projectData, setProjectData] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProject = async () => {
          try {
            const response = await get(`/v0/collection/nft-list?collectionId=${projectId}`, {
              headers: { 'Content-Type': 'application/json' },
            });
            
            setData(response.responseData[0]?.data || []);
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchProject();
    
        const fetchCollection = async () => {
            try {
                const response = await get(getprojects, {
                headers: {
                    "Content-Type": "application/json",
                },
                });
                
                setProjectData(response.responseData[0]?.data);
                
            } catch (error) {
                console.log(error);
            }
            };
            fetchCollection();
      }, [projectId]);

      const projectItem = projectData.find((data) => data?._id === projectId);

      const handleCardClick = (id) => {
        navigate(`/collection/${projectId}/nft/${id}`);
      };

  return (
    <div className=' w-full flex flex-col gap-10 px-20 py-10 bg-[#f4f5f9]'>

        <div className=' flex gap-8 w-full'>

            {/* top left card */}
            <div className=' w-3/5 rounded-lg shadow-md bg-white p-3'>
                <div className=' flex w-full justify-end gap-2'>
                    <HiMiniGlobeAlt className=' text-slate-500 text-2xl p-0.5 rounded-full border border-zinc-400' />
                    <FaTelegramPlane className=' text-slate-500 text-2xl p-0.5 rounded-full border border-zinc-400' />
                    <TiSocialTwitter className=' text-slate-500 text-2xl p-0.5 rounded-full border border-zinc-400' />
                </div>
                <div className=' flex flex-col gap-3 px-5 pb-5'>
                    <div className=' flex gap-3 items-center'>
                        <h1 className=' text-xl text-gray-800 font-bold'>{projectItem?.displayName}</h1>
                        <div className=" select-none whitespace-nowrap h-fit rounded-lg bg-[#04a287] py-1 px-3.5 align-baseline text-[9px] font-bold uppercase text-white">
                            {projectItem?.status}
                        </div>       
                    </div>
                    <p className=' text-sm text-neutral-600'>An exquisite estate property offering a perfect blend of luxury, comfort, and breathtaking views.</p> 
                    <div className=' flex flex-col self-end'>
                        <h1 className=' text-sm text-gray-800 font-semibold'>Total Raise</h1>
                        <span className=' text-xl text-green-600 font-bold'>{projectItem?.totalRaise} USD</span>
                    </div>
                    <div className='w-full h-[400px] rounded-md'>
                        <img src={projectItem?.collectionImage} className=' h-[400px] rounded-md w-full' />
                    </div>
                </div>
            </div>

            {/* top right card */}
            <div className=' w-2/5 flex flex-col items-center rounded-lg shadow-md bg-white'>
                <div className=' w-full py-5 rounded-t-lg bg-[#E7EAF3]'>
                    <h1 className=' text-base text-center text-gray-800 '>Sale Status: <span className={` font-semibold ${projectItem?.status === 'ACTIVE' ? "text-green-500" : "text-red-500"}`}> {projectItem?.status}</span></h1>
                </div>
                <div className=' flex flex-col items-center h-full p-5 justify-between'>
                    <h1 className=' text-lg text-gray-800 font-medium'>Token Distribution Date</h1>
                    <span className=' text-lg text-gray-800 font-semibold py-2 px-5 bg-[#dde1ed] rounded-lg'>TBA</span>
                    <div className=' flex flex-col gap-3 rounded-lg bg-[#E7EAF3] justify-center items-center p-10'>
                        <img src={walletLogo} alt='wallet' className=' w-16' />
                        <h1 className=' text-2xl text-center tracking-wide text-neutral-900 font-bold'>Please Connect Your Web3 Wallet</h1>
                        <span className=' text-sm text-neutral-600 font-medium'>Current Supported Wallet: Metamask</span>
                        <button className=' px-4 my-6 py-2 font-semibold rounded-md text-xl text-white bg-gradient-to-tr from-blue-400 to-green-500'>
                            Connect Wallet
                        </button>
                    </div>
                </div>
            </div>     
        </div>

        <div className=' flex gap-8 w-full'>

            {/* detail card */}
            <div className=' w-3/5 rounded-lg shadow-md bg-white flex flex-col gap-3'>
                <div className=' w-full p-5 border-b-2 '>
                    <h1 className=' text-xl text-neutral-900 font-bold'>Project Details</h1>
                </div>

                <div className=' flex flex-col gap-4 p-5'>

                    <div className=' flex flex-col pb-5 border-b-2 gap-2'>
                        <h1 className=' text-lg text-gray-900 font-semibold'>Introduction</h1>
                        <p className=' text-base text-gray-700'>{projectItem?.displayName} is an exquisite estate property offering a perfect blend of luxury, comfort, and breathtaking views. Nestled atop a serene hill, this prestigious manor boasts panoramic vistas of the surrounding landscape, making it an idyllic retreat for those seeking tranquility and elegance.</p>
                    </div>

                    <div className=' flex flex-col pb-5 border-b-2 gap-2'>
                        <h1 className=' text-lg text-gray-900 font-semibold'>Key Features</h1>
                        <ul className=' list-disc pl-5 text-base text-gray-700'>
                            <li>Spacious Living Areas</li>
                            <li>Luxurious Bedrooms</li>
                            <li>Stunning Views</li>
                            <li>Outdoor Amenities</li>
                            <li>Modern Conveniences</li>
                            <li>Prime Location</li>
                        </ul>
                    </div>

                    <p className='pb-5 text-base text-gray-700'>
                    {projectItem?.displayName} is more than just a home; it's a lifestyle. Perfect for families, retirees, or anyone looking to experience the epitome of luxurious living, this property promises to be a cherished sanctuary for its future owners.
                    </p>

                </div>
            
            </div>

            {/* properties */}
            <div className=' w-2/5 h-fit rounded-lg p-5 shadow-md bg-white items-center flex flex-col gap-3'>
            
                <h1 className=' text-xl text-neutral-900 font-bold'>Properties Listed</h1>
                <div className=' grid grid-cols-2 gap-5'>
                    {data?.map((d,i) => (
                        <div className='relative col-span-1 h-[150px] rounded-lg group cursor-pointer'>
                        <img src={d.coverImage} className=' col-span-1 w-full h-[150px] rounded-lg' />
                        <div onClick={() => handleCardClick(d?._id)} className=' bg-black bg-opacity-40 absolute top-0  left-0 h-[150px] rounded-lg w-full items-center justify-center text-white hidden group-hover:flex '>
                            {d.tokenName}
                        </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>




    </div>
  )
}

export default ProjectDetail