import React, { useContext } from "react";

import { Link, useParams } from "react-router-dom";

import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowUp,
  MdMultilineChart,
} from "react-icons/md";
import { LuHome } from "react-icons/lu";
import { GrMapLocation } from "react-icons/gr";
import { LiaVectorSquareSolid } from "react-icons/lia";

import { FaEdit, FaFire, FaRegBuilding, FaUser } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { IoIosPin } from "react-icons/io";
import { TbShieldUp } from "react-icons/tb";
import { PiCurrencyCircleDollar } from "react-icons/pi";

import "./components/PropertyDetail.css";
import AreaChartComponent from "./components/AreaChartComponent";

import { useEffect, useState } from "react";
import { get } from "../../Service/Apiservice";
import { config } from "../../config";
import { Slider } from "@mui/material";

import { useLocationData } from "../../hooks/useLocationData";
import { getCurrencyAbbreviation } from "country-currency-map";
import axios from "axios";
import { WalletInfoContext } from "../../hooks/useWalletInfo";
import ConfirmInvestmentModal from "./components/ConfirmInvestmentModal";
import { FaFontAwesome } from "react-icons/fa6";
import ShowFractionModal from "./components/ShowFractionModal";
import roadmapImg from "../../assets/images/roadmap.png";
import DetailPageLoader from "../../componets/loader/DetailPageLoader";
import toast from "react-hot-toast";

const PropertyDetail = () => {
  const { collectionId, nftId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { getprojects } = config;
  const [Data, setData] = useState([]);
  const [NftFraction, setNftFractionData] = useState([]);
  const [Nft, setNft] = useState({});
  const [totalFraction, setTotalFraction] = useState(0);
  const [fractionValue, setFractionValue] = useState(0);
  const { walletInfo } = useContext(WalletInfoContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [ogBalance, setOgBalance] = useState(0);
  const [ogBalanceLoaded, setOgBalanceLoaded] = useState(false);
  const apiDepositeUrl = `${baseUrl}/v0/user/getTransactionInfo`;
  const [depositData, setDepositData] = useState([]);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [investnow, setInvestNow] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const userType = localStorage.getItem("userType");
    if (userType === "User") {
      setInvestNow(true);
    }
    const fetchProject = async () => {
      try {
        const response = await get(
          `/v0/collection/nft-list?collectionId=${collectionId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response);
        setData(response.responseData[0]?.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchProject();
    fetchOGBalance();
  }, []);
  const fetchFractions = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    try {
      const response = await axios.get(
        `${baseUrl}/v0/nft/fractions-owner?nftId=${nftId}`,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );
      setNftFractionData(response.data.responseData);
      console.log(response.data.responseData);
    } catch (error) {
      console.log(error);
      setNftFractionData([]);
    }
  };
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await get(`/v0/collection/getNft?nftId=${nftId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log(response.responseData.fractions,"details");
        const fractions = response.responseData.fractions;
        setTotalFraction(fractions.length);
        const availableFraction = fractions.find(
          (item) => item.status === "AVAILABLE"
        );
        if (availableFraction) {
          setFractionValue(availableFraction.fractionNumber);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProject();

    fetchFractions();
    const fetchNFT = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      try {
        const response = await axios.get(
          `${baseUrl}/v0/collection/getNft?nftId=${nftId}`,
          {
            headers: {
              token: token,
              "Content-Type": "application/json",
            },
          }
        );
        setNft(response.data.responseData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNFT();
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      try {
        const response = await axios.get(apiDepositeUrl, {
          headers: {
            accept: "*/*",
            token: token,
          },
        });

        setDepositData(response.data.responseData);
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    };
    fetchData();
  }, []);

  const item = Data.find((data) => data?._id === nftId);
  const [inputValue, setInputValue] = useState(null);
  const { locationData } = useLocationData();
  const [currencyCode, setCurrencyCode] = useState("INR");
  const [exchangeRate, setExchangeRate] = useState(null);
  const [formattedPrice, setFormattedPrice] = useState(null);
  const [inputError, setInputError] = useState(false);

  const inputChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setInputValue("");
      setInputError(false);
    } else {
      const numericValue = parseFloat(value);
      const balance = parseFloat(ogBalance);
      setInputValue(numericValue);

      if (numericValue > balance) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  useEffect(() => {
    if (locationData) {
      const newCurrencyCode = getCurrencyAbbreviation(locationData.country);
      setCurrencyCode(newCurrencyCode);
    }
  }, [locationData]);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/inr.json"
        );
        const rate = response.data["inr"][currencyCode.toLowerCase()];
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    if (currencyCode) {
      fetchRates();
    }
  }, [currencyCode]);

  useEffect(() => {
    if (exchangeRate && item?.price) {
      const price = (item.price * exchangeRate).toFixed(2);
      const countryCode = locationData?.country_code?.toUpperCase();
      let formatted;
      if (countryCode) {
        formatted = new Intl.NumberFormat("en-" + countryCode, {
          currency: currencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(price);
      }

      setFormattedPrice(formatted);
    }
  }, [exchangeRate, item?.price, currencyCode, locationData]);

  const inramount = walletInfo?.inramount || 0;

  const returnInfo = [
    {
      name: "Annual Rental Yield",
      val: item?.AnnualRentalYield + "%",
      icon: <FaRegBuilding />,
    },
    {
      name: "Net Rental Yield",
      val: item?.NetRentalYield + "%",
      icon: <FaRegBuilding />,
    },
    {
      name: "Total Expected Return",
      val: item?.TotalExpectedReturn + "%",
      icon: <FaRegBuilding />,
    },
    {
      name: "Net Expected Return",
      val: "48.33%",
      icon: <FaRegBuilding />,
    },
  ];

  const features = [
    {
      title: Nft?.PropertyType,
      desc: Nft?.PropertyType + " secured starting the 1st of May 2024",
      icon: <FaRegBuilding />,
    },
    {
      title: "The Return Profile",
      desc: "Poised to generate an expected average annualized return of 9.67% as a long-term rental home.",
      icon: <MdMultilineChart />,
    },
    {
      title: "Value for Money",
      desc: "Priced at AED 4,150,000/2,102 PSF, this three-bedroom apartment presents a nearly 4% discount to its independent valuation of AED 4,300,000/2,178 PSF.",
      icon: <IoIosPin />,
    },
    {
      title: "Upgraded",
      desc: "High quality upgrades throughout the unit including the kitchen, bathrooms, flooring, and more.",
      icon: <TbShieldUp />,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFraction, setIsModalOpenFraction] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    fetchFractions();
    setIsModalOpen(false);
  };
  const handleOpenModalFraction = (e) => {
    setselectedFraction(e - 1);
    setIsModalOpenFraction(true);
  };
  const fetchOGBalance = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/v0/user/og-balance`,
        new URLSearchParams({ address: walletInfo.walletAddress }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setOgBalance(parseFloat(response.data.responseData).toFixed(2) || "0");
      setOgBalanceLoaded(true);
    } catch (error) {
      console.error("Error fetching OG balance:", error);
    }
  };
  const handleCloseModalFraction = () => {
    setselectedFraction(0);
    setIsModalOpenFraction(false);
  };
  const rentalMarks = [
    {
      value: 4,
      label: "4%",
    },
    {
      value: 12,
      label: "12%",
    },
  ];
  const annualAppreciatMarks = [
    {
      value: 2,
      label: "2%",
    },
    {
      value: 8,
      label: "8%",
    },
  ];
  const periodsdMark = [
    {
      value: 1,
      label: "1yr",
    },
    {
      value: 5,
      label: "5yr",
    },
  ];

  // functions for calculator
  const [investedAmount, setInvestedAmount] = useState(2000);
  const [annualRental, setAnnualReturn] = useState(4);
  const [annualAppreciation, setAnnualAppreciation] = useState(2);
  const [period, setPeriod] = useState(5);
  const [totalReturn, setTotalReturn] = useState(0);
  const [selectedFraction, setselectedFraction] = useState(0);
  const [totalRentalreturn, setTotalRentalReturn] = useState(0);
  const [totalAnnualAppreciation, setTotalAnnualAppreciation] = useState(0);

  const handleRentalReturn = (event, newValue) => {
    setAnnualReturn(newValue);
  };
  const handleAnnualAppreciation = (event, newValue) => {
    setAnnualAppreciation(newValue);
  };
  const handleInvestedPeriod = (event, newValue) => {
    setPeriod(newValue);
  };
  const calculateAverageAnnualReturn = (beginningValue, endingValue, years) => {
    const totalReturn = endingValue / beginningValue - 1;
    const averageAnnualReturn =
      (Math.pow(1 + totalReturn, 1 / years) - 1) * 100;
    setTotalReturn(averageAnnualReturn);
  };
  const calculateTotalRentalReturn = () => {
    const rental = (annualRental / 100) * investedAmount;
    const total = rental * period;
    setTotalRentalReturn(total);
  };
  const calculateTotalAnnualAppreciation = () => {
    const annaualAppreciation = (annualAppreciation / 100) * investedAmount;
    const total = annaualAppreciation * period;
    setTotalAnnualAppreciation(total);
  };

  useEffect(() => {
    calculateTotalRentalReturn();
    calculateTotalAnnualAppreciation();
    const endingValue =
      totalRentalreturn + totalAnnualAppreciation + investedAmount;
    // console.log(endingValue,"endingValue")
    calculateAverageAnnualReturn(investedAmount, endingValue, period);
  }, [investedAmount, annualRental, annualAppreciation, period]);

  const [investedFraction, setInvestedFraction] = useState(0);
  const calculateInvestedPercent = () => {
    let total = 0;
    NftFraction?.forEach((frac) => {
      if (frac.status === "SOLD") {
        total += 1;
      }
    });
    setInvestedFraction((total / totalFraction) * 100);
  };
  useEffect(() => {
    if (NftFraction.length > 0) {
      console.log(NftFraction, "inner");
      calculateInvestedPercent();
    }
  }, [NftFraction]);
  const handleSetInputValue = (value) => {
    if (value > ogBalance) {
      toast.error("You don't have this much OG balance in your wallet.");
    } else {
      setInputValue(value);
    }
  };
  const calculateProgress = () => {
    if (!NftFraction || NftFraction.length === 0) return 0;
    if (NftFraction.length > 0) {
      const soldItems = NftFraction?.filter(
        (fraction) => fraction.status === "SOLD"
      );
      return (soldItems.length / NftFraction.length) * 100;
    }
  };

  return (
    <div className=" w-full ">
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <div class="loader"></div>
        </div>
      ) : (
        <div className=" w-full flex flex-col gap-10 py-10 px-10 lg:px-15 ">
          <div className=" flex md:flex-row flex-col gap-8 justify-between ">
            <Link
              to={`/investments/${collectionId}`}
              className=" flex items-center gap-4 group text-neutral-800 hover:text-neutral-500"
            >
              <MdKeyboardArrowLeft className=" font-semibold group-hover:translate-x-1 transition-all" />
              <h1 className=" font-semibold ">back to investments</h1>
            </Link>

            <div className=" flex gap-2 md:gap-5 self-start md:self-end">
              <h1 className=" flex items-center gap-1 h-fit py-1 px-3 text-sm bg-[#04a287] rounded-md text-white">
                <LuHome /> {item?.Balanced}
              </h1>
              <h1 className=" flex items-center gap-1 h-fit py-1 px-3 text-sm bg-[#04a287] rounded-md text-white">
                <GrMapLocation /> Rented
              </h1>
              <h1 className=" flex items-center gap-1 h-fit py-1 px-3 text-sm bg-[#04a287] rounded-md text-white">
                <LiaVectorSquareSolid /> {item?.Attribute3}
              </h1>
            </div>
          </div>

          {isLoading && <DetailPageLoader />}

          <div className=" w-full flex md:flex-row flex-col gap-5 lg:gap-8">
            {/* ------------ left side ---------------- */}
            <div className=" md:w-1/3 w-full px-0 sm:px-10 md:pl-0 xl:pl-10 flex flex-col gap-8 ">
              {/* nft image */}

              <div className=" w-full h-[466px] rounded-xl overflow-hidden shadow-xl">
                <img
                  src={item?.coverImage}
                  alt="pic"
                  className=" rounded-xl w-full h-full transition-all object-fill hover:scale-110"
                />
              </div>

              {/* invest now card */}

              <div className=" flex flex-col gap-5 rounded-xl bg-white p-5 shadow-xl">
                <span className=" text-lg text-neutral-400 font-semibold">
                  Property Price
                </span>
                <h1 className=" text-4xl font-semibold text-primary-1">
                  {" "}
                  {formattedPrice || item?.price}{" "}
                  <span className=" text-xl text-neutral-400">
                    {currencyCode}
                  </span>
                </h1>
                <div className=" flex flex-row justify-between">
                  <h1 className=" text-base font-semibold">
                    {investedFraction.toFixed(2)}%{" "}
                    <span className=" text-neutral-400">Funded</span>
                  </h1>
                  <h1 className=" text-base font-semibold text-neutral-400">
                    Remaining <span className=" text-black">19 days</span>
                  </h1>
                </div>
                <div className="relative w-full h-3 rounded-full bg-neutral-200">
                  <div
                    className="w-[85.31%] h-3 absolute top-0 left-0 rounded-full bg-primary-1"
                    style={{ width: `${calculateProgress()}%` }}
                  ></div>
                </div>
                {NftFraction.length > 0 && (
                  <div className="grid grid-cols-3 gap-x-3">
                    <button
                      onClick={() =>
                        handleSetInputValue(NftFraction[0]?.price * 1)
                      }
                    >
                      <div className="col-span-1 cursor-pointer text-xs lg:text-base text-center px-1 flex py-6 items-center justify-center bg-primary-1-light font-semibold rounded-xl">
                        USD {NftFraction[0]?.price * 1}
                      </div>
                    </button>
                    <button
                      onClick={() =>
                        handleSetInputValue(NftFraction[0]?.price * 2)
                      }
                    >
                      <div className="col-span-1 flex flex-col cursor-pointer items-center bg-primary-1-light font-semibold rounded-xl">
                        <div className="flex bg-black py-3 rounded-t-xl text-white text-xs gap-1 text-center w-full items-center justify-center">
                          <FaFire className="lg:block hidden" /> Most Popular
                        </div>
                        <h1 className="text-xs lg:text-base pb-1 text-center">
                          USD {NftFraction[0]?.price * 2}
                        </h1>
                      </div>
                    </button>
                    <button
                      onClick={() =>
                        handleSetInputValue(NftFraction[0]?.price * 3)
                      }
                    >
                      <div className="col-span-1 cursor-pointer flex text-xs lg:text-base text-center px-1 py-6 items-center justify-center bg-primary-1-light font-semibold rounded-xl">
                        USD {NftFraction[0]?.price * 3}
                      </div>
                    </button>
                  </div>
                )}

                {NftFraction.length > 0 && (
                  <div className=" w-full flex border border-black items-center py-2 pl-5 pr-2 rounded-md">
                    <h1 className=" text-base font-semibold mr-5">USD</h1>
                    <input
                      type="text"
                      className="bg-transparent focus:border-none focus:outline-none text-base font-semibold"
                      value={inputValue}
                      onChange={inputChange}
                      readOnly={!ogBalanceLoaded}
                    />
                  </div>
                )}
                {NftFraction.length > 0 && (
                  
                  <>
                    {inputError && (
                      <p className="text-red-500">
                        You don't have enough OG Tokens to invest this amount.
                        Please try a different amount.
                      </p>
                    )}
                    <button
                      onClick={handleOpenModal}
                      className={`flex items-center gap-2 w-full py-2 justify-center text-lg text-white font-semibold  ${
                        investnow ? "bg-primary-1" : "bg-emerald-200 disabled"
                      }`}
                      disabled={!investnow}
                    >
                      <FcMoneyTransfer /> Invest Now
                    </button>
                    {!investnow && (
                      <p className="text-red-500">
                        You are logged in as Admin/Creator. Please login as an
                        investor to invest.
                      </p>
                    )}
                  </>
                )}
                <ShowFractionModal
                  open={isModalOpenFraction}
                  handleClose={handleCloseModalFraction}
                  data={NftFraction}
                  selected={selectedFraction}
                />
                <ConfirmInvestmentModal
                  open={isModalOpen}
                  handleClose={handleCloseModal}
                  nftId={nftId}
                  price={inputValue}
                  priceType={"USD"}
                />
              </div>

              {/* Fraction Owner card */}
              <div className="p-5 flex flex-col gap-4 rounded-xl shadow-md bg-white">
                <h1 className=" text-2xl font-semibold text-neutral-800">
                  Fractions
                </h1>
                <p className="text-yellow-400 text-sm">
                  *Fraction will be available until all parts of the fraction
                  are sold
                </p>
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-primary-1">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          S.no
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Status
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Owner Names
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {NftFraction?.map((fraction, i) => (
                        <tr key={i} className="bg-white border-b">
                          <td className="py-4 px-6">
                            {fraction.fractionNumber}
                          </td>
                          <td
                            className={`py-4 px-6 ${
                              fraction.status === "SOLD"
                                ? "text-red-500"
                                : "text-green-500"
                            }`}
                          >
                            {fraction.status}
                          </td>
                          <td className="py-4 px-6">
                            <button
                              className=" text-black h-5 w-5 ms-8"
                              onClick={() =>
                                handleOpenModalFraction(fraction.fractionNumber)
                              }
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/images/eye-icon.png`}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*  document card */}
              <div className=" p-5 flex flex-col gap-10 rounded-xl shadow-md bg-white">
                <div className=" flex flex-col gap-3">
                  <h1 className=" text-2xl font-semibold text-neutral-800">
                    Documents
                  </h1>
                  <h1 className=" text-lg font-semibold text-neutral-400">
                    Confidential property documents
                  </h1>
                </div>

                <button className=" px-4 py-2 mb-5 self-center text-lg w-fit font-semibold flex items-center gap-2 outline rounded-xl">
                  See More <MdKeyboardArrowDown />
                </button>
              </div>

              {/* financial card */}
              <div className="p-5 flex flex-col rounded-xl shadow-md bg-white">
                <h1 className="text-2xl font-semibold text-neutral-800">
                  Financials
                </h1>

                <button
                  className="px-4 w-full py-2 self-center mt-3 text-lg font-semibold flex items-center justify-center gap-5 outline rounded-xl"
                  onClick={toggleAccordion}
                >
                  <PiCurrencyCircleDollar /> Transaction Details
                  {isOpen ? (
                    <MdKeyboardArrowUp className="ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="ml-auto" />
                  )}
                </button>

                {isOpen && (
                  <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 mt-4">
                      <thead className="text-xs text-white uppercase bg-primary-1">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            S.no
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Price
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {depositData && depositData.length > 0 ? (
                          depositData.map((deposit, i) => (
                            <tr key={i} className="bg-white border-b">
                              <td className="py-4 px-6">{i + 1}</td>
                              <td className="py-4 px-6">
                                {deposit.price || "N/A"}
                              </td>
                              <td className="py-4 px-6 text-green-500">
                                {deposit.transactionStatus}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white border-b">
                            <td colSpan="3" className="py-4 px-6 text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <p className="text-base font-semibold text-neutral-600 mt-4">
                  Only 2 decimal places shown. Calculation carried on actual
                  numbers. Some rounding discrepancies may appear.
                </p>
              </div>

              {/* map card */}
              <div className=" p-5 flex flex-col gap-5 rounded-xl shadow-md bg-white">
                <h1 className=" text-2xl font-semibold text-neutral-800">
                  Property Location
                </h1>

                <div className=" w-full flex flex-col gap-2">
                  <h1 className=" text-lg text-neutral-500 font-semibold">
                    {item?.PropertyLocation}
                  </h1>
                  <div className=" w-full">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.300949792482!2d73.85674241437818!3d18.52043018739438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07a3d4f8f89%3A0x4c6c53adfbd3ec26!2sPune%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sin!4v1719067864158!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>

            {/* ------------ right side ---------------- */}
            <div className=" md:w-2/3 w-full px-0 sm:px-10 md:px-0  flex flex-col gap-8">
              {/* feature card */}
              <div className=" w-full bg-white rounded-xl shadow-lg flex flex-col py-5">
                <div className=" flex w-full flex-col gap-4 py-5 px-8 border-black border-b">
                  <div className=" flex flex-col gap-2">
                    <h1 className=" text-3xl font-bold text-neutral-800">
                      USD {Nft?.price}
                    </h1>
                    <h1 className=" text-3xl font-bold text-neutral-800 capitalize ">
                      {item?.tokenName}{" "}
                      <span className=" text-4xl text-primary-1 ">
                        {" "}
                        ( {fractionValue - 1} / {totalFraction} Own Now )
                      </span>
                    </h1>
                  </div>
                  <div className=" flex items-center ">
                    <span className=" h-fit border-r-2 border-black  pr-3 text-2xl font-bold">
                      {Nft?.Attribute1}
                    </span>
                    <span className=" h-fit border-r-2 border-black  px-3 text-2xl font-bold">
                      {Nft?.Attribute2}
                    </span>
                    <span className=" h-fit  py-1 px-3 text-2xl font-bold">
                      {Nft?.Attribute3}
                    </span>
                  </div>
                </div>
                <div className=" pr-2 xs:pr-8 pl-2 py-5 flex flex-col gap-5 w-full md:scale-100 scale-90">
                  {features.map((feat, i) => (
                    <div className=" flex w-full gap-3 ">
                      <div className=" flex items-center justify-center p-2 w-1/6">
                        <div className=" bg-primary-1 text-4xl text-white rounded-md p-3">
                          {feat.icon}
                        </div>
                      </div>
                      <div className=" flex flex-col w-5/6">
                        <h1 className=" text-2xl text-neutral-800 font-bold">
                          {feat.title}
                        </h1>
                        <h1 className=" text-base font-semibold text-neutral-600">
                          {feat.desc}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* expected return card */}
              <div className=" w-full bg-white rounded-xl shadow-lg flex flex-col gap-5 p-1 pt-4 md:p-5">
                <h1 className=" text-3xl font-bold text-neutral-800">
                  Expected Return
                </h1>

                <div className=" w-full flex flex-wrap justify-between  md:scale-100 scale-90 ">
                  {returnInfo.map((list, i) => (
                    <div className=" flex items-center mb-4 w-1/2 md:gap-2 gap-4 ">
                      <div className=" flex items-center justify-center p-2 w-1/4">
                        <div className=" bg-primary-1 text-xl lg:text-4xl text-white rounded-md p-3">
                          {list.icon}
                        </div>
                      </div>
                      <div className=" flex flex-col w-3/4">
                        <h1 className=" text-xs md:text-base text-primary-1 font-bold">
                          {list.name}
                        </h1>
                        <h1 className=" text-base font-semibold text-neutral-600">
                          {list.val}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* investment calculator card */}
              <div className=" w-full bg-white rounded-xl shadow-md flex flex-col gap-8 p-8">
                <div className=" flex flex-col w-full gap-4">
                  <h1 className=" text-3xl font-bold text-neutral-800">
                    Investment Calculator
                  </h1>
                  <h1 className=" text-2xl font-semibold text-neutral-500">
                    How much can you earn on this investment?
                  </h1>
                </div>

                {/*  range slider */}
                <div className=" flex flex-col gap-12 w-full">
                  <div className=" flex-col flex gap-4 w-full">
                    <div className=" flex justify-between">
                      <h1 className="text-xl font-semibold text-neutral-500">
                        Investment Amount
                      </h1>
                      <div className=" w-full flex border border-black items-center py-2 pl-5 pr-2 rounded-md">
                        <h1 className=" text-base font-semibold mr-5">USD</h1>
                        <input
                          type="text"
                          className=" bg-transparent focus:border-none focus:outline-none text-base font-semibold"
                          value={investedAmount}
                          onChange={(e) => setInvestedAmount(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <Slider
                            defaultValue={30}
                            sx={{
                              width: '90%',
                              height: 15,
                              color: '#04a287',
                            }}
                            className=' self-center'
                          />   */}
                  </div>
                  <div className=" flex-col flex gap-4 w-full">
                    <div className=" flex justify-between">
                      <h1 className="text-xl font-semibold text-neutral-500">
                        Annual Rental Return
                      </h1>
                      <h1 className="text-xl font-semibold text-primary-1">
                        ({annualRental}%)
                      </h1>
                    </div>
                    <Slider
                      // defaultValue={60}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      marks={rentalMarks}
                      value={annualRental}
                      onChange={handleRentalReturn}
                      sx={{
                        width: "90%",
                        height: 15,
                        color: "#04a287",
                      }}
                      className=" self-center"
                      min={4}
                      max={12}
                    />
                  </div>
                  <div className=" flex-col flex gap-4 w-full">
                    <div className=" flex justify-between">
                      <h1 className="text-xl font-semibold text-neutral-500">
                        Expected Annual Appreciation
                      </h1>
                      <h1 className="text-xl font-semibold text-primary-1">
                        ({annualAppreciation}%)
                      </h1>
                    </div>
                    <Slider
                      // defaultValue={60}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      marks={annualAppreciatMarks}
                      value={annualAppreciation}
                      onChange={handleAnnualAppreciation}
                      sx={{
                        width: "90%",
                        height: 15,
                        color: "#04a287",
                      }}
                      className=" self-center"
                      min={2}
                      max={8}
                    />
                  </div>
                  <div className=" flex-col flex gap-4 w-full">
                    <div className=" flex justify-between">
                      <h1 className="text-xl font-semibold text-neutral-500">
                        Investment Period
                      </h1>
                      <h1 className="text-xl font-semibold text-primary-1">
                        ({period} year)
                      </h1>
                    </div>
                    <Slider
                      // defaultValue={60}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      marks={periodsdMark}
                      value={period}
                      onChange={handleInvestedPeriod}
                      sx={{
                        width: "90%",
                        height: 15,
                        color: "#04a287",
                      }}
                      className=" self-center"
                      min={1}
                      max={5}
                    />
                  </div>
                </div>

                <div className=" flex w-full mt-5">
                  <div className=" flex flex-col w-1/2 gap-3">
                    <h1 className="text-lg font-semibold text-neutral-500">
                      {" "}
                      Total return on investment
                    </h1>
                    <div className=" flex ">
                      <h1 className="text-xl font-bold text-neutral-900">
                        USD {totalRentalreturn + totalAnnualAppreciation}
                      </h1>
                      <h1 className="text-xs font-semibold text-neutral-500">
                        {((totalRentalreturn + totalAnnualAppreciation) /
                          investedAmount) *
                          100}
                        % (Return On Investment)
                      </h1>
                    </div>
                  </div>
                  <div className=" flex flex-col w-1/2 gap-3">
                    <h1 className="text-lg font-semibold text-neutral-500">
                      Average annualized return
                    </h1>
                    <div className=" flex ">
                      <h1 className="text-xl font-bold text-neutral-900">
                        {totalReturn.toFixed(2)}%
                      </h1>
                    </div>
                  </div>
                </div>

                <div className=" flex w-full mt-5 gap-8">
                  <div className=" flex flex-col w-1/2 gap-3 border-2 p-5 rounded-lg border-[#04A287]">
                    <h1 className="text-sm font-semibold text-primary-1">
                      Net rental income
                    </h1>

                    <h1 className="text-xl font-bold text-primary-1">
                      {totalRentalreturn} USD
                    </h1>
                  </div>
                  <div className=" flex flex-col w-1/2 gap-3 border-2 p-5 rounded-lg border-[#04A287]">
                    <h1 className="text-sm font-semibold text-primary-1">
                      Expected capital appreciation
                    </h1>

                    <h1 className="text-xl font-bold text-primary-1">
                      {totalAnnualAppreciation} USD
                    </h1>
                  </div>
                </div>

                <div className=" flex flex-col self-end">
                  <h1 className="text-sm font-semibold text-neutral-800">
                    Total expected value after {period} years
                  </h1>

                  <h1 className="text-xl font-bold text-neutral-800">
                    USD{" "}
                    {investedAmount +
                      totalRentalreturn +
                      totalAnnualAppreciation}
                  </h1>
                </div>

                <div className=" w-full flex">
                  <AreaChartComponent />
                </div>

                <div className=" flex flex-col w-full gap-8">
                  <h1 className="text-xl font-bold text-neutral-800">
                    USD 2,000.00
                  </h1>
                  <p className=" text-base text-neutral-500 font-semibold">
                    This for demonstration purposes only. Investment in property
                    and unlisted shares carry risk and you may not receive the
                    anticipated returns and your investments may be at risk.
                  </p>
                </div>

                <button className=" flex items-center gap-2 w-full py-2 justify-center text-2xl text-white font-semibold bg-primary-1">
                  <FcMoneyTransfer /> Invest Now
                </button>
              </div>

              {/* abount property card */}

              <div className=" w-full bg-white rounded-xl shadow-md flex flex-col gap-8 p-8">
                <h1 className=" text-3xl font-bold text-neutral-800">
                  About Property
                </h1>
                <p className=" text-xl font-semibold text-neutral-500">
                  Presenting our three-bedroom apartment in one of Dubai's most
                  sought-after districts - Downtown Dubai. Situated in South
                  Ridge 6, this modern yet luxurious address places you right at
                  the city's centre, surrounded by striking landmarks and
                  world-class amenities.
                </p>
                <p className=" text-xl font-semibold text-neutral-500">
                  Located on the second floor of South Ridge 6, one of South
                  Ridge's Emaar-developed residential towers, this 1,974 sq ft
                  elegantly designed unit features three bedrooms, a kitchen,
                  four bathrooms, a maid's room, a spac
                </p>

                <h1 className=" text-3xl font-bold text-neutral-800">
                  Why you should invest
                </h1>
                <p className=" text-xl font-semibold text-neutral-500">
                  This unit’s allure is evident in every detail - and that
                  includes its investment potential. Priced at AED
                  4,150,000/2,102 PSF, this three-bedroom apartment presents a
                  nearly 4% discount to its independent valuation of AED
                  4,300,000/2,178 PSF. 
                </p>
                <p className="text-xl font-semibold text-neutral-500">
                  Set to operate as a long-term rental home, the unit is
                  expected to yield a potential average annualized return of
                  9.67%. Looking ahead with a projected 6% annual growth, this
                  unit can potentially be worth around AED 5.54 million in
                </p>
              </div>
            </div>
          </div>

          {/*  ------ storyline roadmap ---------- */}
          <div className=" w-full flex py-10 rounded-lg shadow-lg flex-col mt-10 items-center bg-white">
            <h1 className=" text-5xl text-center text-neutral-800 font-bold">
              Roadmap
            </h1>
            <img src={roadmapImg} alt="roadmap" />
            <Link to="/storyline">
              <button className="mt-10 text-xl px-4 py-2 rounded-xl bg-primary-1 text-white hover:bg-primary-1-light">
                View Detailed Roadmap
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyDetail;
