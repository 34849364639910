import React, { useContext, useState } from 'react'
import { FaHouseUser } from 'react-icons/fa';
import { FaUserPen } from 'react-icons/fa6';
import { TbLogout } from 'react-icons/tb';
import { IoIosArrowDown } from "react-icons/io";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLocationData } from '../../hooks/useLocationData';
import { ProfileContext } from '../../hooks/useProfileData';
import { AuthContext } from '../../hooks/useAuth';
import { MdLocationOn } from 'react-icons/md';
import profilePlaceholder from '../../assets/images/profile/profilePlaceholder.jpeg'



const navItems = [
    {
        label: "Collections",
        link: "/collection",
        
    },
    {
        label: "Buy/Sell",
        link: "/trade",
    
    },
    {
        label: "Stake/Farm",
        link: "/dashboard",
        
    },
    // {
    //     label: "Projects",
    //     link: "/projects",
        
    // },
    // {
    //     label: "Dashboard",
    //     link: "/analytics",
        
    // },
  ]

  export default function Header() {

  const [isSideMenuOpen, setSideMenu] = useState(false);
  const { locationData } = useLocationData();

  let navigate = useNavigate();

  const location = useLocation();
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const { profileData } = useContext(ProfileContext);
  const userType = localStorage.getItem("userType");


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    setIsLoggedIn(false);
    navigate("/");
    // Add any additional logout logic here
  };

  const openSideMenu = () => {
    setSideMenu(true);
  };

  const closeSideMenu = () => {
    setSideMenu(false);
  };

  return (
    <div className=" sticky top-0 z-50 mx-auto flex w-full max-w-8xl justify-between px-5 py-2 text-sm bg-white">

      {/* logo */}
      <section className="flex items-center gap-10 min-w-[128px]">
        <Link to="/" className="flex items-center">
        <img src="/originals-logo.svg" alt="logo" className="w-48" />
        </Link>
      </section>

      {isSideMenuOpen && <MobileNav location userType  profileData locationData closeSideMenu={closeSideMenu} />}

      {/* common nav-menu */}
      <section className="hidden lg:flex items-center gap-10">
        <div className='flex items-center gap-4 transition-all'>
          {navItems.map((d, i) => (
            <Link key={i} to={d.link ?? "#"} className='relative group px-2 py-3 transition-all'>
              <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                <span className='text-base'>{d.label}</span>
              </p>
            </Link>
          ))}

          {/* Conditional rendering of dashboard menus according to User type */}

            { userType !== 'Creator' && userType !== 'Admin' &&

            <Link
            to="/analytics"
            className="relative group px-2 py-3 transition-all"
            >
              <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                  <span className='text-base'>Dashboard</span>
              </p>
            </Link>
            
            }

            { userType === 'Creator' &&
                  <Link
                    to="/builderdashboard"
                    className="relative group px-2 py-3 transition-all"
                  >
                    <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                        <span className='text-base'>Creator Area</span>
                    </p>
                  </Link>
                }
                        { userType === 'Admin' && 
                  <Link
                    to="/admindashboard"
                    className="relative group px-2 py-3 transition-all"
                  >
                    <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                        <span className='text-base'>Admin Dashboard</span>
                    </p>
                  </Link>
                }  

           { locationData &&
                  <div className=" flex items-center">
                    <MdLocationOn /> <h1>{locationData.country}</h1>
                  </div>
            }
        </div>

      </section>



      <section className="hidden lg:flex items-center gap-8">
         {/* Conditionally render buttons based on location and login state */}
         {location.pathname !== '/login' && !isLoggedIn && (
                // if not logged in render Login button
                  <Link to="/login">
                    <button className="py-2 px-4 text-white bg-[#04A287] rounded">Login</button>
                  </Link>
                )}

                {/*  if logged in , render profile logo */}
                {isLoggedIn && (
                  
                  <button className=" relative z-50 group transition-all min-w-10 min-h-10">
                  <img src={profileData?.profileImage ? profileData?.profileImage : profilePlaceholder } alt="user" className="w-10 h-10 rounded-full" />
                  <div className='absolute z-50 right-0 top-10 hidden  flex-col gap-1 rounded-lg bg-white py-3 shadow-md transition-all group-hover:flex'>

                    <div className=" w-[160px]">
                      <details className="group">
                        <summary className="flex items-center justify-between font-medium px-5 cursor-pointer list-none">
                          <span className=" flex text-nowrap">My Profile</span>
                          <span className="transition group-open:rotate-180">
                            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="mt-3 group-open:animate-fadeIn">
                          {/* Expanded information */}
                          <div className="flex flex-col">

                            <Link to='/profile/personal-info' className='flex font-semibold cursor-pointe py-1 text-black-400 hover:text-primary-1'>
                              <span className='whitespace-nowrap gap-2 flex items-center pl-3'><FaHouseUser /> Personal Details</span> 
                            </Link>
                            <Link to='/profile' className='flex font-semibold cursor-pointer py-1 text-black-400 hover:text-primary-1'>
                              <span className='whitespace-nowrap gap-2 flex items-center pl-3'><FaUserPen /> Account</span> 
                            </Link>

                          </div>
                          
                        </div>
                      </details>
                    </div>

                              <button onClick={handleLogout} className="py-2 px-3 flex gap-2 items-center text-white transition-all bg-primary-1 hover:bg-opacity-75">
                              <TbLogout /> Logout
                              </button>
                            
                  </div>
                  </button>
                )}
      </section>


      {/* Mobile menu toggle */}
      <FiMenu onClick={openSideMenu} className='cursor-pointer text-4xl lg:hidden' />
    </div>
  );
}


function MobileNav({ location, profileData, locationData, closeSideMenu }) {
    const userType = localStorage.getItem("userType");
    let navigate = useNavigate();
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      setIsLoggedIn(false);
      navigate("/");
      // Add any additional logout logic here
    };

    return (
      <div className="fixed  z-10 left-0 top-0 flex h-full min-h-screen w-full justify-end bg-black/60 lg:hidden">
        <div className="h-full overflow-y-auto w-[40%] bg-white px-4 py-4">
          <section className="flex justify-end">
            <AiOutlineClose onClick={closeSideMenu} className="cursor-pointer text-4xl " />
          </section>
  
          <div className="flex flex-col gap-2 transition-all">
            {navItems.map((d, i) => (
              <Link key={i} to={d.link ?? "#"} className='relative px-2 py-3 transition-all group'>
              <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                <span className='text-lg'>{d.label}</span>
              </p>
            </Link>
            ))}
            { userType === 'Creator' &&
                  <Link
                    to="/builderdashboard"
                    className="relative group px-2 py-3 transition-all"
                  >
                    <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                        <span className='text-lg'>Creator Area</span>
                    </p>
                  </Link>
                }
                        { userType === 'Admin' && 
                  <Link
                    to="/admindashboard"
                    className="relative group px-2 py-3 transition-all"
                  >
                    <p className='flex cursor-pointer font-semibold items-center gap-2 text-black-400 group-hover:text-green-400'>
                        <span className='text-lg'>Admin Dashboard</span>
                    </p>
                  </Link>
                }  

    
          </div>  

           <section className="flex flex-wrap items-center justify-center mt-4 gap-8">
                {/* Conditionally render buttons based on location and login state */}
         {location.pathname !== '/login' && !isLoggedIn && (
                  <Link to="/login">
                    <button className="py-2 px-4 text-white bg-[#04A287] rounded">Login</button>
                  </Link>
                )}
                {isLoggedIn && (
                  
                  

                    <div className=" w-full">
                      <details className="group">
                        <summary className="flex items-center justify-between font-medium px-5 cursor-pointer list-none">
                          <img src={profileData?.profileImage ? profileData?.profileImage : profilePlaceholder } alt="user" className="w-10 h-10 rounded-full" />
                          <span className=" flex text-nowrap">My Profile</span>
                          <span className="transition group-open:rotate-180">
                            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="mt-5 group-open:animate-fadeIn">
                          {/* Expanded information */}
                          <div className="flex flex-col">

                            <Link to='/profile/personal-info' className='flex font-semibold cursor-pointe py-1 text-black-400 hover:text-primary-1'>
                              <span className='whitespace-nowrap gap-2 flex items-center pl-3'><FaHouseUser /> Personal Details</span> 
                            </Link>
                            <Link to='/profile' className='flex font-semibold cursor-pointer py-1 text-black-400 hover:text-primary-1'>
                              <span className='whitespace-nowrap gap-2 flex items-center pl-3'><FaUserPen /> Account</span> 
                            </Link>

                            

                          </div>
                          
                        </div>
                      </details>
                      <button onClick={handleLogout} className="py-2 px-3 flex gap-2 text-lg w-full mt-5 justify-center items-center text-white transition-all bg-primary-1 hover:bg-opacity-75">
                              <TbLogout /> Logout
                            </button>
                    </div>

                )}
           </section>       
  
        </div>
      </div>
    );
  }





